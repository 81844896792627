<template>
  <div>
    <CRow>
      <CCol xs="12" lg="6">
        <CRow>
          <CCol xs="12" md="12">
            <CCard style="width: 100%">
              <CForm :validated="true">
                <CCardBody>
                  <CCardTitle><center>Datos de Facturación</center></CCardTitle>
                  <CRow>
                    <CCol xs="12" md="6">
                      <CSelect
                        aria-label="Tipo de identificación"
                        v-model="form.identityType"
                        :options="identityType"
                        @change="partnerType"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="id-card"
                        /></template>
                      </CSelect>
                    </CCol>
                    <CCol xs="12" md="6">
                      <CSelect
                        aria-label="Tipo de socio de negocio"
                        v-model="form.societyType"
                        :options="societyType"
                        @change="partnerType"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="id-card"
                        /></template>
                      </CSelect>
                    </CCol>
                  </CRow>
                  <CInput
                    placeholder="Identificación"
                    v-model="form.identification"
                    required
                    :valid-feedback="validator.identificationValid"
                    :invalid-feedback="validator.identificationInvalid"
                    @blur="loadPartner"
                    :is-valid="validator.identification"
                    @keypress="onlyNumberId"
                  >
                    <template #prepend-content
                      ><font-awesome-icon
                        v-if="!loadingPartner"
                        icon="id-card" />
                      <CSpinner v-if="loadingPartner" variant="grow" size="sm"
                    /></template>
                  </CInput>
                  <CSelect
                    aria-label="Tipo de contribuyente"
                    v-model="form.typeTaxpayer"
                    v-show="false"
                    :options="typeTaxpayer"
                    @change="partnerType"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="id-card"
                    /></template>
                  </CSelect>
                  <CInput
                    placeholder="Nombre"
                    v-model="name"
                    required
                    valid-feedback="validator.nameValid"
                    invalid-feedback="validator.nameInvalid"
                    :is-valid="validator.name"
                    :readonly="loaded"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="user-tie"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Apellido"
                    v-model="lastname"
                    required
                    valid-feedback="validator.nameValid"
                    invalid-feedback="validator.nameInvalid"
                    :is-valid="validator.name"
                    v-if="this.form.societyType !== '02' && !loaded"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="user-tie"
                    /></template>
                  </CInput>
                  <CInput
                    type="email"
                    placeholder="Correo"
                    autocomplete="email"
                    v-model="form.email"
                    required
                    valid-feedback="validator.emailValid"
                    invalid-feedback="validator.emailInvalid"
                    :is-valid="validator.email"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="at"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Dirección"
                    v-model="form.address"
                    required
                    valid-feedback="validator.addressValid"
                    invalid-feedback="validator.addressInvalid"
                    :is-valid="validator.adress"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="map-marker-alt"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Ciudad"
                    v-model="form.city"
                    required
                    valid-feedback="validator.cityValid"
                    invalid-feedback="validator.cityInvalid"
                    :is-valid="validator.city"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="city"
                    /></template>
                  </CInput>
                  <CInput
                    type="phone"
                    placeholder="Teléfono"
                    autocomplete="phone"
                    v-model="form.phone"
                    valid-feedback="validator.phoneValid"
                    invalid-feedback="validator.phoneInvalid"
                    :is-valid="validator.phone"
                    @keypress="onlyNumber"
                  >
                    <template #prepend-content
                      ><font-awesome-icon icon="phone"
                    /></template>
                  </CInput>
                </CCardBody>
              </CForm>
            </CCard>
          </CCol>
          <!--<CCol xs="12" md="12">
                <CRow>
                    <CCol sm="6" md="4" v-for="(product, index) in products" :key="product.id">
                        <CCard>
                        <img class="imageProduct" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png" alt="" width="100%">
                        <CCardBody>
                            <CCardText><center><strong>{{ product.name }}</strong></center></CCardText>
                            <CCardText><center> ${{ product.sale_price }} </center></CCardText>
                        </CCardBody>
                        <CCardFooter>
                            <center>
                            <CButton class="cartAdd" color="danger" @click="(event) => $store.commit('delete', index)"><font-awesome-icon icon="trash" /></CButton>
                            </center>
                        </CCardFooter>
                        </CCard>
                    </CCol>
                    </CRow>
            </CCol>-->
        </CRow>
      </CCol>
      <CCol xs="12" lg="6">
        <CRow>
          <CCol xs="12" md="12">
            <CCard style="width: 100%">
              <CCardBody v-show="!visibleVerticallyCenteredDemo">
                <CCardTitle><center>Detalles Facturación</center></CCardTitle>
                <p
                  sm="6"
                  md="4"
                  v-for="product in products"
                  :key="'det' + product.ItemCode"
                >
                  <CButton
                    class="cartAdd"
                    color="danger"
                    size="sm"
                    variant="ghost"
                    @click="(event) => $store.commit('delete', index)"
                    ><font-awesome-icon icon="trash"
                  /></CButton>
                  {{ product.quantity }} x {{ product.ItemName }}
                  <span class="prices"
                    >${{ formatPrice(product.Price * product.quantity) }}</span
                  >
                </p>
                <br />
                <hr />
                <p>
                  Subtotal
                  <span class="prices">${{ formatPrice(subtotal) }}</span>
                </p>
                <p>
                  Impuesto <span class="prices">${{ formatPrice(tax) }}</span>
                </p>
                <p>
                  Total <span class="prices">${{ formatPrice(total) }}</span>
                </p>
                <CAlert color="danger" v-if="alert.show">No se pudo completar el pago. Por favor vuelve a intentarlo.</CAlert>
                <div class="d-grid gap-2 col-12 mx-auto">
                  <CSelect
                    v-if="!loading"
                    placeholder="Forma de pago"
                    v-model="paymentType"
                    :options="options"
                    required
                  />
                </div>
                <br>
                <CCol col="12" sm="12" lg="12">
                  <input type="checkbox" v-model="termsagreement" /> Estoy de acuerdo con las <a href="https://www.bsmart.ec/privacidad/" target="_blank">Políticas de privacidad</a>
                </CCol>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <CButton
                    class="js-payment-checkout"
                    color="info"
                    @click="payment(false)"
                  >
                    <font-awesome-icon icon="handshake" v-if="!(loading || loading2)" />
                    <CSpinner v-if="loading || loading2" variant="grow" size="sm" />
                    Pago directo
                  </CButton>
                </div>
                <br />
                <div class="d-grid gap-2 col-6 mx-auto">
                  <CButton
                    class="js-payment-checkout"
                    color="info"
                    @click="payment(true)"
                  >
                    <font-awesome-icon icon="link" v-if="!(loading || loading2)" />
                    <CSpinner v-if="loading || loading2" variant="grow" size="sm" />
                    Compartir enlace de pago
                  </CButton>
                </div>
              </CCardBody>
              <CCardBody v-show="visibleVerticallyCenteredDemo">
                <CCardTitle><center>Enlace de pago</center></CCardTitle>
                <br />
                <center>Comparte el código QR con el cliente para completar el pago</center><br/>
                <center>
                  <qrcode-vue :value="enlace" :size="size" level="H" />
                </center>
                <br/>
                <center>
                  <CButton
                    class="js-payment-checkout"
                    color="info"
                    @click="copy"
                  >
                    <font-awesome-icon icon="copy" /> Copiar enlace
                  </CButton>
                </center>
                <br />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Resource from "@/api/resource";
import QrcodeVue from "qrcode.vue";
import { CModal } from "@coreui/vue";
import SapResource from "@/api/sap";

const transactionResource = new Resource("transaction");
const paymentResponseResource = new Resource("payment_response");
const itemsResource = new Resource("items");
const cartResource = new Resource("cart");
const sapResource = new SapResource();

export default {
  name: "Cart",
  components: {
    QrcodeVue,
    CModal,
  },
  data: function () {
    return {
      termsagreement: false,
      name: "",
      lastname: "",
      size: 300,
      visibleVerticallyCenteredDemo: false,
      enlace: "",
      loading: false,
      loading2: false,
      loadingPartner: false,
      alert: {
        show: false,
        message: "",
      },
      validator: {
        identification: null,
        identificationValid: "",
        identificationInvalid: "",
        name: null,
        nameValid: "",
        nameInvalid: "",
        email: null,
        emailValid: "",
        emailInvalid: "",
        address: null,
        addressValid: "",
        addressInvalid: "",
        phone: null,
        phoneValid: "",
        phoneInvalid: "",
        city: null,
        cityValid: "",
        cityInvalid: "",
      },
      form: {
        name: "",
        identification: "",
        email: "",
        address: "",
        phone: "",
        celphone: "",
        identityType: "02",
        societyType: "01",
        typeTaxpayer: "01",
        total: 0.0,
        taxbaseamount: 0.0,
        tax: 15.0,
        city: "",
      },
      products: [],
      lessons: [],
      show: true,
      isCollapsed: true,
      options: [
        { value: 0, label: "Corriente" },
        { value: 2, label: "Diferido" },
      ],
      identityType: [
        { value: "02", label: "CEDULA" },
        { value: "01", label: "RUC" },
        { value: "03", label: "PASAPORTE" },
      ],
      societyType: [
        { value: "01", label: "Persona Natural" },
        { value: "02", label: "Sociedad" },
      ],
      typeTaxpayer: [
        {
          value: "01",
          label: "Persona Natural No obligada a llevar contabilidad",
        },
        {
          value: "02",
          label: "Persona Natural obligada a llevar contabilidad",
        },
        { value: "03", label: "Sociedad" },
        { value: "04", label: "Contribuyente Especial" },
        { value: "05", label: "Contribuyente Regimen Simplificado" },
        { value: "06", label: "Otros" },
        { value: "07", label: "Servicios Profesionales" },
      ],
      cart: {},
      transaction: {},
      items: {},
      paymentType: 0,
      loaded: false,
    };
  },
  computed: {
    subtotal() {
      var subtotal = 0;
      this.products.map(function (product) {
        subtotal += product.quantity * product.Price;
      });
      return subtotal;
    },
    tax() {
      return (this.subtotal * this.form.tax) / 100;
    },
    total() {
      return this.subtotal + (this.subtotal * this.form.tax) / 100;
    },
  },
  mounted() {
    this.products = this.$store.state.commerce.cart;
    this.form.total = this.total;
    this.form.taxbaseamount = this.subtotal;
    let paymentezScript = document.createElement("script");
    paymentezScript.setAttribute(
      "src",
      "https://cdn.paymentez.com/ccapi/sdk/payment_checkout_stable.min.js"
    );
    paymentezScript.setAttribute("charset", "UTF-8");
    document.head.appendChild(paymentezScript);

    let paymentezStyle = document.createElement("link");
    paymentezStyle.setAttribute(
      "href",
      'https://cdn.paymentez.com/ccapi/sdk/payment_stable.min.css" rel="stylesheet'
    );
    paymentezStyle.setAttribute("rel", "stylesheet");
    paymentezStyle.setAttribute("type", "text/css");
    document.head.appendChild(paymentezStyle);

    window.addEventListener("popstate", function () {
      paymentCheckout.close();
    });
    window.complete = this;
  },
  methods: {
    partnerType() {
      if (this.form.identityType == "02") {
        this.form.societyType = "01";
      }
      if (this.form.societyType == "02") {
        this.form.typeTaxpayer = "03";
      } else {
        this.form.typeTaxpayer = "01";
      }
      this.validateCedula();
    },
    async loadPartner() {
      this.loadingPartner = true;
      this.loaded = false;
      this.validateCedula();
      await sapResource
        .getPartner(this.form.identification)
        .then((response) => {
          this.loadingPartner = false;
          if (response.data) {
            if (response.data.length > 0) {
              this.form.name = response.data[0].CardName;
              this.form.email = response.data[0].E_Mail;
              this.form.address = response.data[0].Street;
              this.form.phone = response.data[0].Phone1;
              this.form.celphone = response.data[0].Cellular;
              this.form.city = response.data[0].City;
              this.form.identityType = response.data[0].U_HBT_TIPO_ID;
              this.form.societyType = response.data[0].U_HBT_TIPPRO;
              this.form.typeTaxpayer = response.data[0].U_HBT_TIPCONT;
              this.name = response.data[0].CardName;
              this.lastname = "";
              this.loaded = true;
            } else {
              this.form.name = "";
              this.form.email = "";
              this.form.address = "";
              this.form.phone = "";
              this.form.celphone = "";
              this.form.city = "";
              this.name = "";
              this.lastname = "";
            }
          } else {
            this.form.name = "";
            this.form.email = "";
            this.form.address = "";
            this.form.phone = "";
            this.form.celphone = "";
            this.form.city = "";
            this.name = "";
            this.lastname = "";
          }
        })
        .catch((error) => {
          this.form.name = "";
          this.form.email = "";
          this.form.address = "";
          this.form.phone = "";
          this.form.celphone = "";
          this.form.city = "";
          this.name = "";
          this.lastname = "";
          this.loadingPartner = false;
        });
    },
    async itemsLoad(linkSend) {
      var completeCorrect = true;
      await this.products.forEach((element) => {
        element.cart_id = this.cart.id;
        element.tax = this.form.tax;
        element.total =
          element.Price * element.quantity +
          (element.Price * element.quantity * element.tax) / 100;
        itemsResource
          .store(element)
          .then((response) => {
            element.data = response.data.item;
          })
          .catch((error) => {
            console.log(error.response);
            this.loading = false;
            this.alert.show = true;
            this.alert.message = "Error al crear los items";
            completeCorrect = false;
          });
      });
      return completeCorrect;
    },
    async payment(link) {
      this.alert.show = false;
      this.loading = true;
      this.form.total = this.total;
      this.form.taxbaseamount = this.subtotal;
      this.form.inventory = false;
      this.form.name = this.lastname.toUpperCase() + " " + this.name.toUpperCase();
      if(this.termsagreement){
        if(this.validator.identification){
          var cartCorrect = false;
          await cartResource
            .store(this.form)
            .then((response) => {
              this.cart = response.data.cart;
              cartCorrect = true;
            })
            .catch((error) => {
              if(error.response.data.errors){
                this.alert.show = true;
                this.alert.message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];  
              }else{
                this.alert.show = true;
                this.alert.message = "Todos los datos del cliente son requeridos";
              }
            }).finally(() => {
              this.loading = false;
            });
          if(cartCorrect){
            const itemsLoad = await this.itemsLoad(link);
            if(itemsLoad){
              if (link) {
                this.link();
              } else {
                this.paymentInit();
              }
            }
          }
        }else{
          this.loading = false;
          this.alert.show = true;
          this.alert.message = "Cedula incorrecta";
        }
      }else{
        this.loading = false;
        this.alert.show = true;
        this.alert.message = "Para continuar debes estar de acuerdo con las políticas de privacidad";
      }
    },
    paymentInit() {
      var paymentCheckout = new PaymentCheckout.modal({
        client_app_code: process.env.VUE_APP_PAYMENTEZ_CODE,
        client_app_key: process.env.VUE_APP_PAYMENTEZ_KEY,
        locale: "es",
        env_mode: process.env.VUE_APP_PAYMENTEZ_ENV,
        onOpen: function () {
          //console.log('modal open');
        },
        onClose: function () {
          window.loading = false;
        },
        onResponse: async function (response) {
          if (response.transaction.status === "success") {
            window.complete.loading2 = true;
            await transactionResource
              .store(response)
              .then((response2) => {
                window.complete.$store.commit("clear");
                window.complete.$router.push({
                  path: "complete/" + window.complete.cart.id,
                });
              })
              .catch((error) => {
                window.complete.loading = false;
                window.complete.alert.show = true;
                window.complete.alert.message = "Error al crear la transaccion";
              }).finally(() => {
                window.complete.loading2 = false;
              });
          } else {
            await paymentResponseResource
              .store(
                {
                  cart: window.complete.cart,
                  response: response,
                  items: window.complete.products
                }
              )
              .then((responseRq) => {
                // console.log(responseRq);
              })
              .catch((error) => {
                console.log(error);
              });
            window.complete.loading = false;
            window.complete.alert.show = true;
            window.complete.alert.message = response.transaction.message;
          }
        },
      });
      paymentCheckout.open({
        user_id: "1",
        user_email: this.form.email,
        user_phone: this.form.phone,
        order_description: this.form.address,
        order_amount: this.roundToTwo(this.form.total),
        order_vat: this.roundToTwo(this.form.total - this.form.taxbaseamount),
        order_installments_type: this.paymentType, //0 corriente, 2 diferido con intereses, 3 diferido sin intereses
        order_taxable_amount: this.roundToTwo(this.form.taxbaseamount),
        order_tax_percentage: 15,
        order_reference: "#" + this.cart.id,
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z0-9])/g, "$1-$2").toLowerCase();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    add(product) {
      product.quantity++;
    },
    less(product) {
      product.quantity--;
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    async link() {
      this.cart.paymentCode = this.uuidv4();
      await cartResource
        .update(this.cart.id, this.cart)
        .then((response) => {
          this.loading = false;
          this.visibleVerticallyCenteredDemo = true;
          this.enlace =
            process.env.VUE_APP_FRONTEND_URL+"/#/pages/Payment/" + this.cart.paymentCode;
          this.$store.commit("clear");
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
          this.alert.show = true;
          this.alert.message = "Error al crear la transaccion";
        });
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.enlace);
        //alert('Copied');
      } catch ($e) {
        //alert('Cannot copy');
      }
    },
    validateCedula() {
      const cedula = this.form.identification;
      if (this.form.identityType == "02") {
        if (cedula.length == 10) {
          var digito_region = cedula.substring(0, 2);
          if (digito_region >= 1 && digito_region <= 24) {
            var ultimo_digito = cedula.substring(9, 10);
            var pares =
              parseInt(cedula.substring(1, 2)) +
              parseInt(cedula.substring(3, 4)) +
              parseInt(cedula.substring(5, 6)) +
              parseInt(cedula.substring(7, 8));
            var numero1 = cedula.substring(0, 1);
            var numero1 = numero1 * 2;
            if (numero1 > 9) {
              var numero1 = numero1 - 9;
            }
            var numero3 = cedula.substring(2, 3);
            var numero3 = numero3 * 2;
            if (numero3 > 9) {
              var numero3 = numero3 - 9;
            }
            var numero5 = cedula.substring(4, 5);
            var numero5 = numero5 * 2;
            if (numero5 > 9) {
              var numero5 = numero5 - 9;
            }
            var numero7 = cedula.substring(6, 7);
            var numero7 = numero7 * 2;
            if (numero7 > 9) {
              var numero7 = numero7 - 9;
            }
            var numero9 = cedula.substring(8, 9);
            var numero9 = numero9 * 2;
            if (numero9 > 9) {
              var numero9 = numero9 - 9;
            }
            var impares = numero1 + numero3 + numero5 + numero7 + numero9;
            var suma_total = pares + impares;
            var primer_digito_suma = String(suma_total).substring(0, 1);
            var decena = (parseInt(primer_digito_suma) + 1) * 10;
            var digito_validador = decena - suma_total;
            if (digito_validador == 10) var digito_validador = 0;
            if (digito_validador == ultimo_digito) {
              this.validator.identification = true;
              this.validator.identificationValid = "Cédula correcta";
            } else {
              this.validator.identification = false;
              this.validator.identificationInvalid = "Cédula incorrecta";
            }
          } else {
            this.validator.identification = false;
            this.validator.identificationInvalid =
              "Cédula no pertenece a ninguna región";
          }
        } else {
          this.validator.identification = false;
          this.validator.identificationInvalid = "Cédula debe tener 10 dígitos";
        }
      }  else if(this.form.identityType == "01"){
        if (cedula.length == 13) {
          this.validator.identification = null;
          this.validCredential = true;
        }else{
          this.validator.identification = false;
          this.validator.identificationInvalid = "RUC debe tener 13 dígitos";
          this.validCredential = false;
        }
      } else {
        this.validator.identification = null;
        this.validCredential = true;
      }
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    onlyNumberId ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if(this.form.identityType !== "03"){
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }
      }
    },
  },
};
</script>

<style scoped>
.prices {
  right: 0;
  position: absolute;
  margin-right: 10px;
}
.js-payment-checkout {
  width: 100%;
}
</style>

import Resource from '@/api/resource';
import request from '@/utils/request';

class SapResource extends Resource {
  constructor() {
    super('partner');
  }

  getPartner($cardCode) {
    return request({
      url: 'partner/'+$cardCode,
      method: 'get',
    });
  }

}

export { SapResource as default };
